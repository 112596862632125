import { Routes, Route } from 'react-router-dom';
import SocialAccountConfig from '../account/social-account-setup/social-account-config';
import SocialAccountSetup from '../account/social-account-setup/social-account-setup';
import Instagram from '../instagram/instagram';
import Settings from '../settings/settings';
import Twitter from '../twitter/twitter';
import Dashboard from './../dashboard/dashboard';
import Layout from './layout';

export default function DashboardStack() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Dashboard />} />
				<Route path="settings" element={<Settings />} />
				<Route path="instagram" element={<Instagram />} />
				<Route path="twitter" element={<Twitter />} />
			</Route>
			<Route path='/setup-account' element={<SocialAccountSetup />}></Route>
			<Route path='/setup-account-config' element={<SocialAccountConfig />}></Route>
		</Routes>
	)
}
