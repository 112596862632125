import React, { ChangeEvent, PureComponent, useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  ComposedChart,
  BarChart,
} from 'recharts';
import { getDateStringDayMonth, getLast3Months, getLastWeeksDate, getTodayStartDate } from '../../helper';
import { CustomTooltip } from '../components/RechartTooltip';
import { Channel } from '../constants';
import { getSocialAccount, getTwitterMetric, getUserNetworkKPI } from '../services/api';
import './twitter.scss';

/* eslint-disable-next-line */
export interface TwitterProps { }

export function Twitter(props: TwitterProps) {
  const [followerInteraction, setFollowerInteraction] = useState<any>({ tweets: [] });
  const [accounts, setAccounts] = useState<any[]>([]);
  const [networkKPI, setNetworkKPI] = useState<any[]>([{ value: 0 }])
  const [dateRangeFilter, setDateRangeFilter] = useState<string>(getLastWeeksDate());

  const onChangeDateRange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    switch (+value) {
      case 1:
        setDateRangeFilter(getTodayStartDate());
        break;
      case 2:
        setDateRangeFilter(getLastWeeksDate());
        break;
      case 3:
        setDateRangeFilter(getLast3Months());
        break;
      default:
        setDateRangeFilter(getLastWeeksDate());
        break;
    }
  }

  useEffect(() => {
    const fetchNetwork = async () => {
      const accounts = await getSocialAccount();
      const kpi = await getUserNetworkKPI();

      if (accounts.status === 200) {
        setAccounts(accounts.data.filter((account: any) => account.user_network.channel === Channel.Twitter));
      }

      if (kpi.status === 200) {
        const arr = [...kpi.data.filter((account: any) => account.user_network.channel === Channel.Twitter)];
        console.log(arr);
        setNetworkKPI(arr);
      }
    }

    fetchNetwork();
  }, []);

  useEffect(() => {
    const fetchTwitterMetric = async () => {
      if (!accounts.length) return;

      const metric = await getTwitterMetric(accounts[0].account_name, dateRangeFilter);
      if (metric.status === 200) {
        setFollowerInteraction(metric.data);
      }
    }

    fetchTwitterMetric()
  }, [accounts, dateRangeFilter])

  const graphData = followerInteraction.tweets.map((tweet: any) => ({
    text: tweet.text,
    name: getDateStringDayMonth(tweet.published_at),
    reply: tweet.reply_count,
    retweet: tweet.retweet_count,
    quote: tweet.quote_count,
    like: tweet.like_count,
    kpi: tweet.kpi
  }));

  return (
    <div className="flex-1 p-4 min-h-screen">
      <div className="grid grid-cols-2 h-full">
        <div className="col-span-2 py-4 flex flex-row items-center mb-12">
          <span className="block p-2 flex-1 text-slate-700 font-semibold text-xl">Twitter Analytics</span>
          {/* <input type="date" className="rounded-sm bg-gray-200 border-transparent focus:bg-gray-100 focus:outline-none" /> */}
          <select defaultValue={2} onChange={onChangeDateRange} className="ml-2 rounded-sm bg-gray-200 border-transparent focus:bg-gray-100 focus:outline-none">
            <option disabled defaultValue="">Date Range</option>
            <option value={1}>Today</option>
            <option value={2} >Last week</option>
            <option value={3}>Last 3 months</option>
          </select>
        </div>
        <div className="bg-white rounded-sm shadow-sm m-1 col-span-2">
          <span className="block my-5 text-indigo-500 font-normal text-l text-center">Follower Interaction Graphic</span>
          <ResponsiveContainer height={400}>
            <LineChart
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line type="monotone" dataKey="reply" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="retweet" stroke="#82ca9d" />
              <Line type="monotone" dataKey="quote" stroke="#d5cb9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white rounded-sm shadow-sm m-1 col-span-2">
          <span className="block my-5 text-indigo-500 font-normal text-l text-center">Target KPI Tracking</span>
          <ResponsiveContainer height={400}>
            <ComposedChart
              data={graphData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale='auto' />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="kpi" barSize={20} fill="#413ea0" />
              <Line type="monotone" dot={false} name="target kpi" dataKey={() => +networkKPI[0].value} stroke="#ff7300" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="col-span-2 bg-white rounded-sm shadow-sm m-1">
          <span className="block my-5 text-indigo-500 font-normal text-l text-center">Count of Follower Interaction(Retweet/Reply)</span>
          <ResponsiveContainer height={400}>
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar yAxisId="left" dataKey="like" fill="#8884d8" />
              <Bar yAxisId="right" dataKey="retweet" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Twitter;
