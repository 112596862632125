export const CustomTooltip = (props: any) => {
	if (props.active && props.payload && props.payload.length) {
		console.log(props)
		return (
			<div className="custom-tooltip bg-white p-5 font-normal border-gray-300 border max-w-md">
				<span className="block font-light">{props.payload[0].payload.text}</span>
				<span className="block my-1">{props.payload[0].payload.name}</span>
				{props.payload.map((payload: any) =>
					<span style={{ color: payload.color }} className="block my-1">{payload.name}: {payload.value}</span>
				)}
			</div>
		);
	}

	return null;
};