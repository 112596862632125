import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Authentication from './stack/authentication.stack';
import Dashboard from './stack/dashboard.stack'
import PrivateRoute from './stack/private';


export function Navigation() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="auth/*" element={<Authentication />} />
				<Route path="/" element={<PrivateRoute />}>
					<Route path="/" element={<Navigate replace to="/app" />} />
					<Route path="/app/*" element={<Dashboard />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}
