export class Endpoints {
	static base = 'https://api.duxbilisim.com/api';

	static userNetworkKPI = '/user-network-kpi';

	static auth = {
		base: '/auth',
		login: '/login',
		refresh: '/refresh',
		register: '/register'
	};

	static network = {
		base: '/network',
		preferences: '/user/get-preferences',
		checkUsername: '/channel/twitter/account/:username',
		channelInstagram: '/channel/instagram/account/:username',
		channelTwitter: '/channel/twitter/account/:accountId/tweets',
		loginInstagram: '/channel/instagram/account/:username/do-login',
		twoFAInstagram: '/channel/instagram/account/:username/2fa',
		twitterAccess: '/channel/twitter/account/:username/code'
	}

	static post = {
		base: '/post',
		twitter: '/channel/twitter/users/:userId',
	}

	static user = {
		base: '/user',
		socialAccount: '/social-account',
		dashboard: '/dashboard'
	}

	static metric = {
		base: '/metric',
		twitter: '/twitter',
		instagram: '/instagram'
	}
}

export interface ApiResponse {
	error: boolean;
	status: number;
	message: string;
	data: any
}

export enum Channel {
	Facebook = 'fb',
	Twitter = 'tw',
	Instagram = 'ig',
	Linkedin = 'ld',
	Youtube = 'yt'
}

export enum SocialAccount {
	Facebook = 1,
	Twitter = 2,
	Instagram = 3,
	Linkedin = 4,
	Youtube = 5
}

export function getChannelKey(value: string) {
	return Object.entries(Channel).find(([key, val]) => val === value)?.[0];
}