import { Routes, Route } from 'react-router-dom';
import Login from '../auth/login/login';
import Register from '../auth/register/register';
import Public from './public';

export default function AuthenticationStack() {
	return (
		<Routes>
			<Route path="/" element={<Public />}>
				<Route index element={<Login />} />
				<Route path='/login' element={<Login />}></Route>
				<Route path='/register' element={<Register />}></Route>
			</Route>
		</Routes>
	)
}
