import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getNetworkPreferences, login } from '../../services/api';
import './login.module.scss';

/* eslint-disable-next-line */
export interface LoginProps { }

export function Login(props: LoginProps) {
  const [credentials, setCrecentials] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const doLogin = async () => {
    if (!credentials.email.length) return setErrorMessage('Email is missing.');
    else if (!credentials.password.length) return setErrorMessage('Password is missing.');

    const result = await login(credentials);

    if (result.error === false) {
      const { access_token, refresh_token, ...user } = result.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('user', JSON.stringify(user));

      const networkPreferences = localStorage.getItem('is_set_network_preferences');

      if (!networkPreferences) {
        const response = await getNetworkPreferences();
        if (response.data && !response.data.length) return navigate('/app/setup-account');
      }

      return navigate('/app');
    }

    if (result.status === 401) {
      setErrorMessage('Invalid email or password');
    }
  }

  return (
    <div className="p-20 h-screen w-screen flex flex-col-reverse md:flex-row items-center justify-center bg-gray-100">
      <div className="content text-3xl text-center md:text-left">
        <h1 className="text-5xl text-sky-600 font-bold mb-4">DUXAI</h1>
        <p>Improve your social media post with the right content with more quality creative in understanding all channel structures.</p>
      </div>
      <div className="container mx-auto flex flex-col items-center">
        <h3 className="my-4 text-2xl font-semibold text-gray-700">Sign in</h3>
        <div className="shadow-lg w-80 p-4 flex flex-col bg-white rounded-lg">
          <input onChange={(e) => setCrecentials({ ...credentials, email: e.target.value })} type="text" placeholder="Email" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <input onChange={(e) => setCrecentials({ ...credentials, password: e.target.value })} type="password" placeholder="Pasword" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <button onClick={doLogin} className="w-full bg-indigo-500 text-white p-3 rounded-lg font-semibold text-lg">Login</button>
          {/* <a href="#" className="text-blue-400 text-center my-2">Forgot Pasword?</a> */}
          <hr />
          <Link to="/auth/register">
            <button className="w-full bg-emerald-500 mt-8 mb-4 text-white p-3 rounded-lg font-semibold text-lg">Create New Account</button>
          </Link>
          {
            errorMessage.length ?
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errorMessage}
              </span> : ''
          }
        </div>
        <p className="text-center text-sm my-4">
          <span className="font-semibold text-center w-full">Individual account</span> for your personal accounts.
        </p>
      </div>
    </div>
  );
}

export default Login;
