import { CheckIcon, PlusIcon } from '@heroicons/react/solid'
import { useState, ChangeEvent, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Channel, SocialAccount } from '../../constants';
import {
  accessTwitter,
  checkUsername,
  createNetworkChannel,
  createSocialAccount,
  getSocialAccount,
  instagramLogin,
  instagramTwofa,
} from '../../services/api';
import { NetworkPreferences } from '../../services/dto';
import './social-account-config.module.scss';

/* eslint-disable-next-line */
export interface SocialAccountConfigProps { }

interface Form {
  [Channel.Instagram]?: any;
  [Channel.Twitter]?: any;
  [Channel.Youtube]?: any;
  [Channel.Linkedin]?: any;
  [Channel.Facebook]?: any;
  [key: string]: any;
}

export function SocialAccountConfig(props: SocialAccountConfigProps) {
  const location = useLocation();
  const [queries] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isPublic, setIsPublic] = useState(new Map([[Channel.Twitter, 1]]));
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [preferences, setPreferences] = useState([]);
  const [socialAccounts, setSocialAccounts] = useState([])
  const [igTwofa, setIgTwofa] = useState('');
  const [showigTwofa, setShowIgTwofa] = useState(false);
  const tempState: { [key: string]: boolean } = {}
  const formDefault = {
    [Channel.Twitter]: {
      account_name: '',
      account_id: null,
      user_network_id: null,
      account_type: SocialAccount.Twitter,
      credentials: {},
      is_public: 1
    },
    [Channel.Instagram]: {
      account_name: '',
      user_network_id: null,
      pwd: '',
      account_type: SocialAccount.Instagram,
      password: '',
      credentials: {},
      is_public: 0
    },
    [Channel.Twitter + '_form']: false,
    [Channel.Instagram + '_form']: false,
  }

  const twQuery = {
    client_id: 'QWlfSWIxcXVzRkpOcnVyLTR4QXQ6MTpjaQ',
    response_type: 'code',
    scope: 'tweet.read%20users.read%20follows.read%20follows.write%20offline.access',
    state: 'state',
    code_challenge: 'challenge',
    code_challenge_method: 'plain',
    json: ''
  }

  const [isSuccess, setIsSuccess] = useState(tempState);
  const [form, setForm] = useState<Form>(formDefault);
  const navigate = useNavigate();

  const checkNavigationState = async () => {
    let callback;

    if (location.state) {
      console.log(location.state)
      setSelected((location.state as any).selected);
      setPreferences((location.state as any).preferences);
    } else if ((callback = queries.get('json'))) {
      const decoded = JSON.parse(atob(callback));
      const code: string = queries.get('code') as string;
      const username: string = queries.get('account_name') as string;

      setSelected(decoded.selected);
      setPreferences(decoded.preferences);

      const accessIsOk = await accessTwitter(code, username);
    } else {
      // window.location.href = '/app';
    }
  }

  useEffect(() => {
    checkNavigationState();

    preferences.forEach((s: any) => {
      tempState[s.channel] = false;
      (formDefault[s.channel] as any).user_network_id = s.id;
    });

    const fetchAccounts = async () => {
      console.log('Fetching accounts...');
      const accounts = await getSocialAccount();
      setSocialAccounts(accounts.data);

      const updatedForm: any = {};
      const updatedIsSuccess: any = {};
      for (let i = 0; i < accounts.data.length; i += 1) {
        const account = accounts.data[i];
        const un = account.user_network;
        updatedIsSuccess[un.channel] = account.is_active;
        updatedForm[un.channel] = { account_name: account.account_name, is_public: account.is_public };
      }

      setForm({
        ...form,
        ...updatedForm
      });
      setIsSuccess({ ...isSuccess, ...updatedIsSuccess });
    }

    fetchAccounts();
  }, []);

  const authorizeTwitter = () => {
    const { preferences, selected } = location.state as any;
    const json = { preferences, selected, account_name: form[Channel.Twitter].account_name }
    twQuery.json = btoa(JSON.stringify(json));
    
    const url = 'https://twitter.com/i/oauth2/authorize?' + new URLSearchParams(twQuery).toString();

    window.location.href = url;
  }

  const onClickSection = (index: number) => {
    if (activeIndex === index) setActiveIndex(-1);
    else setActiveIndex(index);
  }

  const onClickIsPublic = (key: Channel) => {
    setIsPublic(prev => new Map([...prev, [key, +!isPublic.get(key)]]));
  }

  const onChangeTwitter = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;

    if (value && value.length)
      setForm({ ...form, [Channel.Twitter]: { ...form[Channel.Twitter], account_name: value.replace('@', '') } });
    else setForm({ ...form, [Channel.Twitter + '_form']: false });
  }

  const onChangeInstagram = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;

    if (value && value.length) {
      if (e.target.type === 'text')
        setForm({ ...form, [Channel.Instagram + '_form']: true, [Channel.Instagram]: { ...form[Channel.Instagram], account_name: value } });
      else if (e.target.type === 'password')
        setForm({ ...form, [Channel.Instagram + '_form']: true, [Channel.Instagram]: { ...form[Channel.Instagram], pwd: value } });
    } else setForm({ ...form, [Channel.Instagram + '_form']: false });
  }

  const onLoginInstagram = async () => {
    const { account_name, pwd } = form[Channel.Instagram];
    const response = await instagramLogin({ username: account_name, password: pwd }, account_name);

    if (!response.data.is_logged_in && response.data.twofa) {
      setShowIgTwofa(true);
    } else if (response.data.is_logged_in) {
      setIsSuccess({ ...isSuccess, [Channel.Instagram]: true });
    }
  }

  const onCheckTwitterAccount = async () => {
    const username = form[Channel.Twitter].account_name.replace('@', '');
    const user = await checkUsername(username);

    if (user.data && user.data.id) {
      setForm({ ...form, [Channel.Twitter + '_form']: true, [Channel.Twitter]: { ...form[Channel.Twitter], account_id: user.data.id } });
    }
  }

  const onChange2fa = (e: ChangeEvent<HTMLInputElement>) => {
    setIgTwofa(e.target.value);
  }

  const onClickSendTwofa = async () => {
    const { account_name } = form[Channel.Instagram];
    await instagramTwofa({ code: igTwofa }, account_name);
  }

  const submit = async () => {
    // 1. post to create network
    const networkArr = Object.entries(selected).filter(([_, value]) => value === true).map(([i]) => i);

    const channel = await createNetworkChannel({ channel: networkArr });
    channel.data.forEach((ch: NetworkPreferences) => {
      setForm({
        ...form,
        [ch.channel]: { ...form[ch.channel], user_network_id: ch.id, is_public: isPublic.get(ch.channel) || 0 },
      });
    })
    // 2. post for social account create
    if (form[Channel.Twitter + '_form']) {
      setIsSuccess({ [Channel.Twitter]: true });
      console.log('Twitter is fully entered.', form.tw);
      const twResponse = await createSocialAccount(form[Channel.Twitter]);
      if (!twResponse.error) toast.success(`${Channel.Twitter} is done!`, { autoClose: 1500 });
      else toast.error(`${Channel.Twitter} error!`, { autoClose: 1500 });
    }

    if (form[Channel.Instagram + '_form']) {
      console.log('Instagram is fully entered.', form.ig);
      const igResponse = await createSocialAccount(form[Channel.Instagram]);
      if (!igResponse.error) toast.success(`${Channel.Twitter} is done!`, { autoClose: 1500 });
      else toast.error(`${Channel.Twitter} error!`, { autoClose: 1500 });
    }

    setTimeout(() => {
      navigate('/app');
    }, 2000);
  }

  return (
    <div className="h-screen grid place-items-center">
      <div className="w-6/12 mx-auto rounded-lg shadow-lg bg-gray-50">
        <div className="p-10 shadow-sm">
          <h3 className="text-lg font-medium text-gray-800">You social media accounts</h3>
          <p className="text-sm font-light text-gray-600 my-3">
            We need you some social media account information and/or credentials.
            We absolutely don't store your credentials.
          </p>

          <div className="h-1 w-full mx-auto border-b my-5"></div>
          {selected[Channel.Instagram] ?
            <div className="transition hover:bg-indigo-50">

              <div onClick={() => onClickSection(0)} className="cursor-pointer transition flex space-x-5 px-5 items-center h-16 font-semibold">
                <PlusIcon className="w-5 h-5" />
                <h3>Instagram</h3>
                {isSuccess[Channel.Instagram] ?
                  <>
                    <span className="font-light">{form[Channel.Instagram].account_name}</span>
                    <CheckIcon className="w-5 h-5 text-green-500" />
                  </> : <CheckIcon className="w-5 h-5 text-gray-500" />}
              </div>

              <div style={{ maxHeight: `${activeIndex === 0 && !isSuccess[Channel.Instagram] ? '25em' : ''}` }} className={'px-5 pt-0 relative overflow-hidden transition-all duration-500 max-h-0'}>
                <p className="leading-6 font-light pl-9 text-justify">
                  We don't absolutely your instagram password infromation.
                </p>
                <div className="flex justify-center py-4">
                  <svg role="img" className="w-12 h-12 fill-sky-700" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg>
                </div>
                <div className="pl-9 my-4 flex flex-col">
                  {showigTwofa ?
                    <>
                      <input value={igTwofa} onChange={onChange2fa} type="text" placeholder="Your verify code" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
                      <button onClick={onClickSendTwofa} className="w-40 bg-indigo-500 text-white mb-3 p-3 rounded-lg font-semibold text-sm shadow-lg">Enter Code</button>
                    </> :
                    <>
                      <input onChange={onChangeInstagram} type="text" placeholder="Username" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
                      {
                        isPublic.get(Channel.Instagram) ? ''
                          :
                          <>
                            <input onChange={onChangeInstagram} type="password" placeholder="Pasword" className="transition mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
                            <button onClick={onLoginInstagram} className="w-40 bg-indigo-500 text-white mb-3 p-3 rounded-lg font-semibold text-sm shadow-lg">Sign in</button>
                          </>
                      }
                      <div className="flex my-3 items-center">
                        <label className="inline-flex items-center">
                          <input
                            checked={!!isPublic.get(Channel.Instagram)}
                            onChange={() => onClickIsPublic(Channel.Instagram)}
                            className="text-indigo-500 cursor-pointer w-6 h-6 mr-2 focus:ring-indigo-400 focus:ring-opacity-25 border border-gray-300 rounded-full"
                            type="checkbox" />
                        </label>
                        Is public account ?
                      </div>
                    </>
                  }
                </div>
              </div>
            </div> : ''
          }
          {selected[Channel.Twitter] ?
            <div className="transition hover:bg-indigo-50">

              <div onClick={() => onClickSection(1)} className="cursor-pointer transition flex space-x-5 px-5 items-center h-16 font-semibold">
                <PlusIcon className="w-5 h-5" />
                <h3>Twitter</h3>
                {isSuccess[Channel.Twitter] ?
                  <>
                    <span className="font-light">{form[Channel.Twitter].account_name}</span>
                    <CheckIcon className="w-5 h-5 text-green-500" />
                  </> : <CheckIcon className="w-5 h-5 text-gray-500" />}
              </div>

              <div style={{ maxHeight: `${activeIndex === 1 && !isSuccess[Channel.Twitter] ? '20em' : ''}` }} className={'px-5 pt-0 relative overflow-hidden transition-all duration-500 max-h-0'}>
                <p className="leading-6 font-light pl-9 text-justify">
                  We need only your twitter account.
                </p>
                <div className="flex justify-center py-4">
                  <svg role="img" className="w-12 h-12 fill-blue-500" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>
                </div>
                <div className="pl-9 my-4 flex flex-col">
                  {
                    isPublic.get(Channel.Twitter) ?
                      <>
                        <input onChange={onChangeTwitter} type="text" placeholder="@username" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
                        <button onClick={onCheckTwitterAccount} className="w-40 bg-indigo-500 text-white mb-3 p-3 rounded-lg font-semibold text-sm shadow-lg">Check</button>
                        <span className=" font-light text-gray-500">{form[Channel.Twitter].account_id ? `Id: ${form[Channel.Twitter].account_id}` : ''}</span>
                      </>
                      : <div className="w-full sm:pr-2 mb-3 sm:mb-0">
                        <button
                          onClick={authorizeTwitter}
                          className="w-full rounded-md bg-blue-500 hover:bg-blue-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                          type="button"
                        >Login with twitter</button>
                      </div>
                  }
                  <div className="flex my-3 items-center">
                    <label className="inline-flex items-center">
                      <input
                        checked={!!isPublic.get(Channel.Twitter)}
                        onChange={() => onClickIsPublic(Channel.Twitter)}
                        className="text-indigo-500 cursor-pointer w-6 h-6 mr-2 focus:ring-indigo-400 focus:ring-opacity-25 border border-gray-300 rounded-full"
                        type="checkbox" />
                    </label>
                    Is public account ?
                  </div>
                </div>
              </div>
            </div> : ''
          }
        </div>
      </div>
      <div className="w-6/12 mx-auto flex justify-between">
        <Link to="/app/setup-account" state={{ preferences, choices: selected }}>
          <button className="w-40 bg-gray-50 text-slate-700 my-6 p-3 rounded-lg font-semibold text-lg border-gray-400 border">Back</button>
        </Link>
        <button onClick={submit} className="w-40 bg-indigo-500 text-white my-6 p-3 rounded-lg font-semibold text-lg shadow-lg">Send</button>
      </div>
    </div>
  );
}

export default SocialAccountConfig;
