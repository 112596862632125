import { Outlet, Navigate } from 'react-router-dom'

export default function Private() {
	const auth = localStorage.getItem('access_token');

	return (
		auth
			? <Outlet />
			: <Navigate replace to="/auth/login" />
	);
}