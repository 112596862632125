export function getLastWeeksDate() {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString();
}

export function getTodayStartDate(){
  const start = new Date();
  start.setUTCHours(0,0,0,0);

  return new Date(start.toUTCString()).toISOString();
}

export function getLast3Months(){
  const d = new Date();
  
  return new Date(d.setMonth(d.getMonth() - 3)).toISOString();
}

export function getDateStringDayMonth(date: string){
	const d = new Date(date);
	return `${d.getDate()}/${d.getMonth() + 1}`
}