import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { register } from '../../services/api';
import './register.module.scss';

/* eslint-disable-next-line */
export interface RegisterProps { }

export function Register(props: RegisterProps) {
  const [form, setForm] = useState({ name: '', email: '', phone: null, password: '', confirmPassword: '', create: true });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const submit = async () => {
    const { name, email, password, confirmPassword, phone, create } = form;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const ph = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    console.log(form);

    if (!name.length) return setErrorMessage('Name is required');
    if (!email.length) return setErrorMessage('Email is required.');
    if (!re.test(email)) return setErrorMessage('Invalid Email.');
    if (phone && !ph.test(phone)) return setErrorMessage('Phone is not valid.');
    if (!password.length || !confirmPassword.length) return setErrorMessage('Password is required.');
    if (password !== confirmPassword) return setErrorMessage('Password is not matched.');
    if (password.length < 6) return setErrorMessage('Password must be at least 6 character.');

    const nid = toast.loading('You are registering.', { autoClose: false, hideProgressBar: false });
    const response = await register({ name, email, password, phone, create });

    setTimeout(() => {
      if (response.error === false) toast.update(nid, { render: 'You are registered.', type: toast.TYPE.SUCCESS, autoClose: 2000, hideProgressBar: true, isLoading: false });
      else toast.update(nid, { render: 'We could not registered you.!', type: 'error', autoClose: 2000, hideProgressBar: true, isLoading: false });

      navigate('/auth/login');
    }, 2000);
  }

  const onChangeInput = (value: any) => {
    setForm({ ...form, ...value });
  }

  return (
    <div className="p-20 h-screen w-screen flex flex-col-reverse md:flex-row items-center justify-center bg-gray-100">
      <div className="container mx-auto flex flex-col items-center">
        <h3 className="my-4 text-2xl font-semibold text-gray-700">Register</h3>
        <div className="shadow-lg w-80 p-4 flex flex-col bg-white rounded-lg">
          <input onChange={(e) => onChangeInput({ name: e.target.value })} type="text" placeholder="Name*" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <input onChange={(e) => onChangeInput({ email: e.target.value })} type="text" placeholder="Email*" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <input onChange={(e) => onChangeInput({ phone: e.target.value })} type="text" placeholder="Phone" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <input onChange={(e) => onChangeInput({ password: e.target.value })} type="password" placeholder="Pasword* (min 6 character)" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <input onChange={(e) => onChangeInput({ confirmPassword: e.target.value })} type="password" placeholder="Confirm Pasword*" className="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
          <button onClick={submit} className="w-full bg-indigo-500 text-white p-3 rounded-lg font-semibold text-lg">Register</button>
          <Link className="text-blue-400 text-center my-2" to="/auth/login">
            <p>Already have an account</p>
          </Link>
          {
            errorMessage.length ?
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errorMessage}
              </span> : ''
          }
        </div>
        <p className="text-center text-sm my-4">
          <span className="font-semibold text-center w-full">Individual account</span> for your personal accounts.
        </p>
      </div>
    </div>
  );
}

export default Register;
