import { ApiResponse, Endpoints } from "../constants";
import { CreateNetworkChannelDto, CreateSocialAccountDto, CreateUserDto, InstagramLoginDto, InstagramTwofaDto, Login } from "./dto";
import { ServiceCall } from "./service-call";

export async function login(credentials: Login): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.auth.base + Endpoints.auth.login, {body: JSON.stringify(credentials), method: 'POST'});
	return ServiceCall.unauthorized(r);
}

export async function register(form: CreateUserDto): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.auth.base + Endpoints.auth.register, {body: JSON.stringify(form), method: 'POST'});
	return ServiceCall.unauthorized(r);
}

export async function getNetworkPreferences(): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.network.base + Endpoints.network.preferences, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function createNetworkChannel(form: CreateNetworkChannelDto): Promise<ApiResponse> {
	const r = new Request(Endpoints.base + Endpoints.network.base, {body: JSON.stringify(form), method: 'POST'});
	return ServiceCall.authorized(r);
}

export async function instagramLogin(form: InstagramLoginDto, username: string): Promise<ApiResponse> {
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.loginInstagram;
	url = url.replace(':username', username);

	const r = new Request(url, {body: JSON.stringify(form), method: 'POST'});
	return ServiceCall.authorized(r);
}

export async function instagramTwofa(form: InstagramTwofaDto, username: string): Promise<ApiResponse> {
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.twoFAInstagram;
	url = url.replace(':username', username);

	const r = new Request(url, {body: JSON.stringify(form), method: 'POST'});
	return ServiceCall.authorized(r);
}

export async function createSocialAccount(form: CreateSocialAccountDto): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.user.base + Endpoints.user.socialAccount, {body: JSON.stringify(form), method: 'PUT'});
	return ServiceCall.authorized(r);
}

export async function getSocialAccount(): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.user.base + Endpoints.user.socialAccount, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function getDashboardData(): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.user.base + Endpoints.user.dashboard, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function checkUsername(username: string): Promise<ApiResponse>{
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.checkUsername;
	url = url.replace(':username', username);
	const r = new Request(url, {method: 'GET'});
	
	return ServiceCall.authorized(r);
}

export async function syncInstagramSocialAccount(username: string): Promise<ApiResponse>{
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.channelInstagram;
	url = url.replace(':username', username);
	const r = new Request(url, {method: 'GET'});
	
	return ServiceCall.authorized(r);
}

export async function syncTwitterSocialAccount(accountId: string, username: string): Promise<ApiResponse>{
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.channelTwitter;
	url = url.replace(':accountId', accountId);
	const r = new Request(`${url}?username=${username}`, {method: 'GET'});
	
	return ServiceCall.authorized(r);
}

export async function getTwitterMetric(username: string, date: string): Promise<ApiResponse>{
	const url = Endpoints.base + Endpoints.metric.base + Endpoints.metric.twitter;
	const r = new Request(`${url}?social_account=${username}&min_date=${date}`, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function getInstagramMetric(username: string, date: string): Promise<ApiResponse>{
	const url = Endpoints.base + Endpoints.metric.base + Endpoints.metric.instagram;
	const r = new Request(`${url}?social_account=${username}&min_date=${date}`, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function getUserNetworkKPI(): Promise<ApiResponse>{
	const r = new Request(Endpoints.base + Endpoints.userNetworkKPI, {method: 'GET'});
	return ServiceCall.authorized(r);
}

export async function accessTwitter(code: string, username: string): Promise<ApiResponse> {
	let url = Endpoints.base + Endpoints.network.base + Endpoints.network.twitterAccess;
	url = url.replace(':username', username);

	const r = new Request(`${url}?code=${code}`, {method: 'GET'});
	return ServiceCall.authorized(r);
}