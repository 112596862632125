import {
  CogIcon,
  LogoutIcon,
  ChartPieIcon
} from '@heroicons/react/outline'
import { useState } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom'
import './sidebar.scss';

/* eslint-disable-next-line */
export interface SidebarProps { }

export function Sidebar(props: SidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user') as string);

  const onClickLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    navigate('/auth/login');
  }

  return (
    <div className="w-72 h-screen sticky top-0">
      <div className="flex w-full md:max-w-xs p-4 bg-white min-h-screen flex-col justify-between">
        <ul className="flex flex-col w-full">
          <Link to="/app" >
            <li>
              <div className="flex justify-center py-10 border-b border-gray-100">
                <div className="items-center flex flex-col">
                  <p className="text-2xl font-bold text-indigo-600">DUXAI</p>
                  <span className="text-xs block text-gray-800">DASHBOARD</span>
                </div>
              </div>
            </li>
          </Link>
          <li className="my-px">
            <span className="flex font-medium text-sm text-gray-400 px-4 my-4 uppercase">
              Apps
            </span>
          </li>
          <NavLink to="/app/instagram" className={({ isActive }) => (isActive ? 'text-indigo-800 nav-link' : '')}>
            <li className="my-px sidebar-li-item">
              <span className="flex items-center justify-center text-lg text-gray-400">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 333333 333333" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"><defs><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="126963" y1="323675" x2="206370" y2="9655.51"><stop offset="0" stopColor="#f58529"/><stop offset=".129" stopColor="#feda77"/><stop offset=".431" stopColor="#dd2a7b"/><stop offset=".741" stopColor="#8134af"/><stop offset="1" stopColor="#515bd4"/></linearGradient></defs><path d="M129816 80249h73706c27678 0 50313 22355 50313 49665v73506c0 27317-22635 49663-50313 49663h-73706c-27680 0-50317-22348-50317-49663v-73508c0-27308 22638-49663 50317-49663zM166667 0c46024 0 87691 18656 117851 48816 30159 30160 48816 71827 48816 117851 0 46020-18656 87689-48816 117849-30161 30159-71830 48816-117851 48816-46020 0-87689-18657-117851-48816C18657 254356 0 212687 0 166667S18656 78978 48816 48816C78977 18657 120646 0 166667 0zm104924 61742c-26849-26849-63948-43459-104924-43459-40974 0-78074 16610-104924 43459-26849 26850-43459 63950-43459 104924 0 40976 16610 78074 43459 104923 26850 26849 63950 43459 104924 43459s78074-16610 104924-43459c26849-26849 43459-63947 43459-104923s-16610-78076-43459-104924zm-105394 58260c26896 0 48723 21826 48723 48724 0 26900-21827 48723-48723 48723-26904 0-48727-21823-48727-48723 0-26898 21823-48724 48727-48724zm0 16457c17811 0 32267 14453 32267 32267 0 17815-14456 32271-32267 32271-17817 0-32270-14456-32270-32271 0-17814 14453-32267 32270-32267zm47139-23209c4368 0 7911 3542 7911 7908 0 4369-3543 7911-7911 7911-4367 0-7908-3542-7908-7911 0-4367 3542-7908 7908-7908zm-77498-18883h61667c23156 0 42094 18843 42094 41872v61975c0 23027-18937 41867-42094 41867l-61667 2c-23154 0-42095-18841-42095-41867v-61978c0-23027 18941-41870 42095-41870z" fill="url(#a)"/></svg>
              </span>
              <span className="ml-3">Instagram</span>
            </li>
          </NavLink>
          <NavLink to="/app/twitter" className={({ isActive }) => (isActive ? 'text-indigo-800 nav-link' : '')}>
            <li className="my-px sidebar-li-item">
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg className="h-6 w-6" viewBox="0 0 333333 333333" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"><path d="M333328 63295c-12254 5480-25456 9122-39241 10745 14123-8458 24924-21861 30080-37819-13200 7807-27871 13533-43416 16596-12499-13281-30252-21537-49919-21537-37762 0-68336 30591-68336 68330 0 5326 591 10537 1748 15562-56820-2880-107194-30081-140915-71467-6049 10435-9250 22300-9250 34367v8c0 23696 12031 44654 30389 56876-11202-333-21739-3457-30991-8519v854c0 33138 23554 60789 54852 67039-5734 1557-11787 2417-18023 2417-4417 0-8673-455-12905-1224 8742 27139 33975 46923 63857 47500-23430 18356-52858 29286-84939 29286-5537 0-10931-339-16318-984 30326 19458 66251 30727 104844 30727 125735 0 194551-104198 194551-194543 0-3002-67-5911-191-8852 13354-9553 24932-21609 34097-35333l31-31-6 4z" fill="#1da1f2" /></svg>
              </span>
              <span className="ml-3">Twitter</span>
            </li>
          </NavLink>
          <li className="my-px">
            <span className="flex font-medium text-sm text-gray-400 px-4 my-4 uppercase">
              System
            </span>
          </li>
          <NavLink to="settings" className={({ isActive }) => (isActive ? 'text-indigo-800 nav-link' : '')}>
            <li className="my-px sidebar-li-item">
              <span className="flex items-center justify-center text-lg text-gray-400">
                <CogIcon className="h-6 w-6" />
              </span>
              <span className="ml-3">Settings</span>
            </li>
          </NavLink>
          <li onClick={onClickLogout} className="my-px sidebar-li-item red-hover cursor-pointer">
            <span className="flex items-center justify-center text-lg text-gray-400">
              <LogoutIcon className="h-6 w-6" />
            </span>
            <span className="ml-3">Logout</span>
          </li>
        </ul>
        <div className="px-4 border-t border-gray-100">
          <span className="flex font-medium text-sm text-gray-400 my-1 uppercase">
            User
          </span>
          <p className="text-lg text-indigo-600">{user.email} <span className="text-gray-500 font-extralight text-sm"> Id: {user.id}</span></p>
          <span className="text-xs font-extralight text-slate-500">Version: 0.1.0</span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
