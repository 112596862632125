import { StrictMode } from 'react';
import { ToastContainer } from 'react-toastify';
import { Navigation } from './navigation';

export default function Entry() {
  return (
    <StrictMode>
      <Navigation />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        limit={3}
      />
    </StrictMode>
  )
}
