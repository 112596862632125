import { Link, useNavigate } from 'react-router-dom';
import { getNetworkPreferences } from '../services/api';
import './settings.module.scss';

/* eslint-disable-next-line */
export interface SettingsProps { }

export function Settings(props: SettingsProps) {
  const navigate = useNavigate();
  const onClickSocialAccounts = async () => {
    const preferences = await getNetworkPreferences();
    const choices = preferences.data.reduce((obj: any, item: any) => Object.assign(obj, { [item.channel]: true }), {})

    navigate('/app/setup-account', { state: { preferences: preferences.data, choices } });
  }
  const user = JSON.parse(localStorage.getItem('user') as string);

  return (
    <div className="flex h-full p-5 align-start justify-center">
      <div className="container sm:w-full md:w-1/2">
        <div className="bg-white shadow-sm rounded-sm mb-4 p-4 flex-col flex flex-1">
          <span className="text-slate-700 text-3xl text-center border-b mb-4 py-4">{user.name}
            <span className="text-gray-700 text-xl block">{user.email}</span>
          </span>
          <span className="text-gray-500 text-sm"><span className="text-xs">Id:</span> {user.id}</span>
          <span className="text-gray-500 text-sm"><span className="text-xs">Created at:</span> {user.created_at}</span>
        </div>
        <div className="flex-1 justify-start">
          <div className="bg-white shadow-sm rounded-sm">
            <ul className="divide-y divide-gray-100">
              <li className="p-4 hover:bg-gray-50 cursor-pointer">Profile Settings</li>
              <li onClick={onClickSocialAccounts} className="p-4 hover:bg-gray-50 cursor-pointer">Social Accounts</li>
              <li className="p-4 hover:bg-gray-50 cursor-pointer">Privacy & Policy</li>
              <li className="p-4 hover:bg-gray-50 cursor-pointer">Terms & Conditions</li>
              <li className="p-4 hover:bg-gray-50 cursor-pointer text-red-500">Delete Account</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
