import { RefreshIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiResponse, Channel, getChannelKey } from '../constants';
import { getDashboardData, syncInstagramSocialAccount, syncTwitterSocialAccount } from './../services/api'
import './dashboard.module.scss';

/* eslint-disable-next-line */
export interface DashboardProps { }

export function Dashboard(props: DashboardProps) {
  const [dashboard, setDashboard] = useState({ accounts: [] });
  const [isSyncing, setIsSyncing] = useState<number[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const dash = await getDashboardData();
      setDashboard({ ...dash.data });
    }

    fetch();
  }, []);

  const syncSocialAccount = async (account: any) => {
    const now = Date.now();
    const oneday = 60 * 60 * 24 * 1000;
    if (now < new Date(account.last_sync_at).getTime() + oneday) return toast.warning('You can sync one time in a day.', { autoClose: 2000 });

    console.log('Sync', account.id);
    setIsSyncing([...isSyncing, account.id]);

    let response: ApiResponse | null = null;
    switch (account.user_network.channel) {
      case Channel.Instagram:
        response = await syncInstagramSocialAccount(account.account_name);
        break;

      case Channel.Twitter:
        response = await syncTwitterSocialAccount(account.account_id, account.account_name);
        break;
      default:
        break;
    }

    if (response && response.error && response.message === 'Login required') {
      toast.error(`Authentication required for ${account.account_name}`);
    }

    const index = isSyncing.indexOf(account.id);
    isSyncing.splice(index, 1);

    return setIsSyncing(isSyncing);
  }

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-14 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Hi There!</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">We will make your social media the best together. Let's get started!</p>
        </div>
        <div className="flex flex-wrap -m-4 text-center mb-20">
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-md">
              <svg fill="none" stroke="currentColor" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M8 17l4 4 4-4m-4-5v9"></path>
                <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
              </svg>
              <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
              <p className="leading-relaxed">Post</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-md">
              <svg fill="none" stroke="currentColor" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
              </svg>
              <h2 className="title-font font-medium text-3xl text-gray-900">1.3K</h2>
              <p className="leading-relaxed">Gold Package</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-md">
              <svg fill="none" stroke="currentColor" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
              </svg>
              <h2 className="title-font font-medium text-3xl text-gray-900">74</h2>
              <p className="leading-relaxed">Files</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-md">
              <svg fill="none" stroke="currentColor" strokeWidth="2" className="svg-icon text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path fill="none" d="M14.023,12.154c1.514-1.192,2.488-3.038,2.488-5.114c0-3.597-2.914-6.512-6.512-6.512
								c-3.597,0-6.512,2.916-6.512,6.512c0,2.076,0.975,3.922,2.489,5.114c-2.714,1.385-4.625,4.117-4.836,7.318h1.186
								c0.229-2.998,2.177-5.512,4.86-6.566c0.853,0.41,1.804,0.646,2.813,0.646c1.01,0,1.961-0.236,2.812-0.646
								c2.684,1.055,4.633,3.568,4.859,6.566h1.188C18.648,16.271,16.736,13.539,14.023,12.154z M10,12.367
								c-2.943,0-5.328-2.385-5.328-5.327c0-2.943,2.385-5.328,5.328-5.328c2.943,0,5.328,2.385,5.328,5.328
								C15.328,9.982,12.943,12.367,10,12.367z"></path>
              </svg>
              <h2 className="title-font font-medium text-3xl text-gray-900">2</h2>
              <p className="leading-relaxed">Bound Account</p>
            </div>
          </div>
        </div>

        <div className="container w-1/2">
          <span className="block p-2 mb-2 text-slate-700 font-semibold text-xl">Last Post Sync
            <span className="text-xs text-gray-400 block font-light">(You can sync one time in day)</span>
          </span>
          <ul className="flex flex-col divide divide-y bg-white rounded-md shadow-md">
            {dashboard.accounts.map((account: any, index: number) => <li key={index} className="flex flex-row py-2">
              <div className="select-none cursor-auto flex flex-1 items-center p-2 justify-between">
                <span className="w-12 p-2 mr-2 border-r text-slate-700 font-medium text-md">#{index + 1}</span>
                <div className="pl-1">
                  <div className="font-medium dark:text-white">
                    {getChannelKey(account.user_network.channel)}
                  </div>
                  <div className="text-gray-600 dark:text-gray-200 text-sm">
                    {account.account_name}
                  </div>
                  <span className="text-slate-700 font-light text-xs">Id: {account.id}</span>
                </div>
                <div className="flex flex-1 justify-end items-center">
                  <div className="text-gray-500 dark:text-gray-200 text-xs mr-2">
                    {isSyncing.includes(account.id) ? 'Syncing...' : account.last_sync_at || 'Not yet synced.'}
                  </div>
                  {isSyncing.includes(account.id) ?
                    <RefreshIcon className="w-7 h-7 cursor-pointer text-green-500 animate-spin" />
                    : <RefreshIcon onClick={() => syncSocialAccount(account)} className="w-7 h-7 cursor-pointer text-gray-500" />
                  }
                </div>
              </div>
            </li>)
            }
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
