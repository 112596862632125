import { Outlet, Navigate } from 'react-router-dom'

export default function Public() {
	const auth = localStorage.getItem('access_token');

	return (
		auth
			? <Navigate replace to="/app" />
			: <Outlet />
	);
}
