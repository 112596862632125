import { Outlet } from 'react-router';
import Sidebar from '../components/sidebar/sidebar';

export default function Layout() {
	return (
		<div className="w-full flex flex-row bg-gray-50">
			<Sidebar />
			<div className="flex flex-1 p-4 flex-col overflow-y-scroll no-scrollbar">
				<Outlet />
			</div>
		</div>
	)
}
