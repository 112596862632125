import { toast } from "react-toastify";
import { ApiResponse, Endpoints } from "../constants";

export class ServiceCall {
	static async handleUnauthorized(){
		const refreshToken = localStorage.getItem('refresh_token');
		const accessToken = localStorage.getItem('access_token');

		if(!accessToken || !refreshToken) return;

		const r = new Request(
			Endpoints.base + Endpoints.auth.base + Endpoints.auth.refresh, 
			{body: JSON.stringify({refresh_token: refreshToken, access_token: accessToken}), method: 'POST'});
		
		try {
			const response = await ServiceCall.unauthorized(r);
			console.log(response);
			if(response.data['access_token'] && response.data['refresh_token']){
				localStorage.setItem('access_token', response.data['access_token']);
				localStorage.setItem('refresh_token', response.data['refresh_token']);

				return true;
			}

			console.error('Incorrect response');
		} catch (error) {
			console.error(error);

			localStorage.removeItem('refresh_token');
			localStorage.removeItem('access_token');
		}

		return false;
	}

	static handleResponse(resolver: (value: ApiResponse)=>void){
		return async (data: any) => {
			const code = data.status;

			if(code === 401){
				const authorized = await ServiceCall.handleUnauthorized();
				console.log('Re-Authenticated.', authorized);
				if(authorized){
					return;
				}
			} else if(code === 500) {
				toast.error('Server is unavailable', { autoClose: 3000, isLoading: false, hideProgressBar: true });
				throw new Error(JSON.stringify(data));
			}

			resolver(data);
		}
	}

	static authorized(request: Request): Promise<ApiResponse>{
		return new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('access_token');

			if(!accessToken) reject(new Error('Unauthorized token.'));

			request.headers.append('Authorization', `Bearer ${accessToken}`);
			request.headers.set('Content-Type', 'application/json');
			fetch(request)
			.then(res => res.json())
			.then(ServiceCall.handleResponse(resolve))
			.catch(e => reject(e));
		});
	}

	static unauthorized(request: Request): Promise<ApiResponse>{
		return new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('access_token');
			
			if(accessToken) request.headers.append('Authorization', `Bearer ${accessToken}`);
			request.headers.set('Content-Type', 'application/json');

			fetch(request)
			.then(res => res.json())
			.then(ServiceCall.handleResponse(resolve))
			.catch(e => reject(e));
		});
	}
}